import React from 'react';
import logo from '../../../assets/img/logo-grey.svg'

import HeaderNav from "../HeaderNav/HeaderNav";

import './headerMenu.scss';


const HeaderMenu = (props: { burger: boolean }) => {
  return (
    <div className={`header-menu${props.burger ? ' active' : ''}`}>
      <div className="content">
        <img src={logo} alt="" />
        <HeaderNav />
        <ul className="header-bottom">
          <li>
            <a href="tel:89650014371">
              <svg className="phone" width="19" height="19" viewBox="0 0 19 19" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M18 14.8805C18 15.1865 17.9319 15.501 17.7872 15.807C17.6425 16.113 17.4552 16.402 17.2083 16.674C16.7912 17.133 16.3315 17.4645 15.8122 17.677C15.3015 17.8895 14.7481 18 14.1522 18C13.2839 18 12.356 17.796 11.3771 17.3795C10.3981 16.963 9.41913 16.402 8.44867 15.6965C7.45958 14.9741 6.52623 14.1785 5.65648 13.3165C4.79565 12.4512 4.00169 11.5221 3.28142 10.537C2.58337 9.568 2.02153 8.599 1.61292 7.6385C1.20431 6.6695 1 5.743 1 4.859C1 4.281 1.10215 3.7285 1.30646 3.2185C1.51077 2.7 1.83425 2.224 2.28543 1.799C2.83025 1.2635 3.42614 1 4.05608 1C4.29444 1 4.5328 1.051 4.74562 1.153C4.96695 1.255 5.16274 1.408 5.31597 1.629L7.29094 4.4085C7.44417 4.621 7.55483 4.8165 7.63145 5.0035C7.70806 5.182 7.75063 5.3605 7.75063 5.522C7.75063 5.726 7.69104 5.93 7.57186 6.1255C7.46119 6.321 7.29945 6.525 7.09514 6.729L6.44817 7.4005C6.35453 7.494 6.31197 7.6045 6.31197 7.7405C6.31197 7.8085 6.32048 7.868 6.33751 7.936C6.36305 8.004 6.38858 8.055 6.40561 8.106C6.55884 8.3865 6.82273 8.752 7.1973 9.194C7.58037 9.636 7.98898 10.0865 8.43165 10.537C8.89134 10.9875 9.334 11.404 9.78518 11.7865C10.2278 12.1605 10.5939 12.4155 10.8833 12.5685C10.9259 12.5855 10.977 12.611 11.0366 12.6365C11.1047 12.662 11.1728 12.6705 11.2494 12.6705C11.3941 12.6705 11.5048 12.6195 11.5984 12.526L12.2454 11.8885C12.4582 11.676 12.6625 11.5145 12.8583 11.4125C13.0541 11.2935 13.2499 11.234 13.4627 11.234C13.6244 11.234 13.7947 11.268 13.982 11.3445C14.1693 11.421 14.365 11.5315 14.5779 11.676L17.3956 13.6735C17.6169 13.8265 17.7702 14.005 17.8638 14.2175C17.9489 14.43 18 14.6425 18 14.8805Z"
                  strokeWidth="1.5" strokeMiterlimit="10" />
              </svg>
              +7 965 001-43-71</a>
          </li>
          <li>
            <a href="mailto:info@vkamne.com">
              <svg className="email" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.9557 1.89783C17.8809 1.52109 17.7122 1.16502 17.4661 0.86566C17.4161 0.802811 17.3638 0.746898 17.3067 0.69018C16.8688 0.251569 16.2598 -1.90735e-06 15.6356 -1.90735e-06L2.3638 -1.90735e-06C1.73271 -1.90735e-06 1.13917 0.245228 0.69251 0.690426C0.635979 0.746618 0.583561 0.803091 0.53167 0.867937C0.286631 1.16603 0.118689 1.52161 0.0456699 1.89888C0.0151543 2.04704 -0.000244141 2.20055 -0.000244141 2.35585L-0.000244141 11.6444C-0.000244141 11.9675 0.0656387 12.2822 0.196244 12.5813C0.309271 12.847 0.480764 13.0988 0.692369 13.3096C0.745666 13.3624 0.798611 13.4112 0.855143 13.4587C1.27821 13.8079 1.81392 14 2.3638 14H15.6356C16.1891 14 16.7243 13.8071 17.1462 13.4549C17.2026 13.4094 17.2549 13.3617 17.3071 13.3096C17.5117 13.106 17.6742 12.872 17.7911 12.614L17.8065 12.5772C17.9347 12.2838 17.9998 11.9701 17.9998 11.6444V2.35585C17.9998 2.20258 17.9849 2.04805 17.9557 1.89783ZM1.224 1.50347C1.25744 1.4547 1.30012 1.40464 1.35457 1.34995C1.62489 1.08073 1.98338 0.932572 2.36377 0.932572H15.6356C16.0193 0.932572 16.3779 1.08097 16.6453 1.35048C16.6914 1.39697 16.7355 1.44857 16.774 1.50014L16.8755 1.63607L9.79147 7.78839C9.57308 7.97918 9.2919 8.08418 8.99965 8.08418C8.71028 8.08418 8.42935 7.97943 8.20835 7.78864L1.13144 1.63782L1.224 1.50347ZM0.940361 11.7264C0.936529 11.7011 0.93565 11.673 0.93565 11.6444V2.54818L6.43303 7.32705L0.991127 12.0551L0.940361 11.7264ZM16.3439 12.8799C16.1311 13.0022 15.8861 13.0671 15.6356 13.0671H2.3638C2.11324 13.0671 1.86831 13.0022 1.65569 12.8799L1.43322 12.7514L7.05597 7.86855L7.67219 8.40266C8.04182 8.72244 8.51319 8.89873 8.99972 8.89873C9.48804 8.89873 9.96019 8.72244 10.3296 8.40266L10.9455 7.86831L16.5664 12.7517L16.3439 12.8799ZM17.0636 11.6444C17.0636 11.6725 17.0631 11.7003 17.0595 11.7251L17.0108 12.0569L11.5666 7.3296L17.0636 2.55049V11.6444Z" />
              </svg>
              info@vkamne.com</a>
          </li>
          <li>
            <p>Следи за нами в соц сетях:</p>
            <div className='social'>
              <a href='t.me/vkamnecom'>
                <svg width="23" height="19" viewBox="0 0 23 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fillRule="evenodd" clipRule="evenodd"
                    d="M2.23197 8.03004C7.95849 5.52509 11.7864 3.90604 13.685 3.11179C19.1359 0.851228 20.269 0.454102 21.0039 0.454102C21.1571 0.454102 21.5245 0.48465 21.7695 0.667939C21.9533 0.820679 22.0145 1.03452 22.0451 1.18726C22.0758 1.34 22.1064 1.67603 22.0758 1.95096C21.7695 5.03632 20.514 12.5817 19.8403 16.0336C19.5647 17.5 19.0134 17.9887 18.4928 18.0498C17.3598 18.1415 16.4717 17.2861 15.3693 16.5835C13.6544 15.4532 12.6745 14.7506 10.9902 13.6509C9.06092 12.3679 10.3165 11.6653 11.4189 10.535C11.6945 10.2295 16.7473 5.67783 16.8392 5.25016C16.8392 5.18906 16.8698 5.00577 16.7473 4.91413C16.6248 4.82248 16.4717 4.85303 16.3492 4.88358C16.1655 4.91413 13.4094 6.74702 8.05036 10.3517C7.25416 10.9016 6.54983 11.1459 5.90674 11.1459C5.20241 11.1459 3.85499 10.7488 2.84443 10.4128C1.61951 10.0157 0.639567 9.80183 0.731437 9.12977C0.823306 8.76319 1.31328 8.39662 2.23197 8.03004Z" />
                </svg>
              </a>
              <a href="https://wa.me/89650014371" target='_blank'>
                <svg fill="#A19A9A" width="23" height="19" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 308 308" xmlSpace="preserve">
                  <g id="XMLID_468_">
                    <path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"/>
                    <path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"/>
                  </g>
                </svg>
              </a>
              <a href="https://www.instagram.com/vkamne_com?igsh=ZWNmYmhxMHFhaXV1">
                <svg fill="#A19A9A" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z" /></svg>
              </a>
              <a href="https://vk.com/spbvkamne">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 24"><path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm.581 15.997c.304 0 .429-.204.425-.458-.016-.958.356-1.474 1.03-.802.744.744.896 1.26 1.801 1.26h1.601c.403 0 .562-.13.562-.334 0-.432-.711-1.194-1.312-1.752-.844-.783-.882-.802-.156-1.744.9-1.169 2.079-2.667 1.037-2.667h-1.991c-.387 0-.414.217-.551.542-.498 1.173-1.443 2.693-1.803 2.461-.377-.243-.204-1.203-.175-2.63.008-.377.006-.636-.571-.77-.314-.073-.621-.103-.903-.103-1.137 0-1.922.477-1.477.56.785.146.711 1.846.527 2.58-.319 1.278-1.518-1.012-2.018-2.152-.12-.275-.155-.488-.586-.488h-1.627c-.247 0-.394.08-.394.258 0 .301 1.479 3.36 2.892 4.885 1.379 1.487 2.742 1.354 3.689 1.354z" /></svg>
              </a>
              <a href="https://www.facebook.com/vkamne?mibextid=ZbWKwL">
                <svg fill="#A19A9A" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M22.675 0h-21.35c-.732 0-1.325.593-1.325 1.325v21.351c0 .731.593 1.324 1.325 1.324h11.495v-9.294h-3.128v-3.622h3.128v-2.671c0-3.1 1.893-4.788 4.659-4.788 1.325 0 2.463.099 2.795.143v3.24l-1.918.001c-1.504 0-1.795.715-1.795 1.763v2.313h3.587l-.467 3.622h-3.12v9.293h6.116c.73 0 1.323-.593 1.323-1.325v-21.35c0-.732-.593-1.325-1.325-1.325z" /></svg>
              </a>

            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default HeaderMenu;