import React from 'react'
import './OurWorksVideos.scss'

export default function OurWorksVideos() {



  return (
    <iframe
      className='youtubevideo'

      src="https://www.youtube.com/embed/videoseries?si=QHlLmxf-8qYd4Lb9&amp;list=PLQscktBzfdGBG5_1BJCDjfYoleh4VaRKj"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen></iframe>
  )
}
