import React, { useEffect, useState } from "react";

import "./headerItems.scss";
import { useLocation } from "react-router-dom";
import axios from "axios";

type HeaderItemsT = {
  burger: boolean,
  setBurger: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderItems: React.FC<HeaderItemsT> = ({ burger, setBurger }) => {
  const [dollar, setDollar] = useState(0);
  const [euro, setEuro] = useState(0);
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  useEffect(() => {
    axios.get("https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usd.json")
      .then((res: any) => setDollar(res.data.usd.rub.toFixed(2)))
      .catch((err: any)=> console.log(err))
    axios.get("https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/eur.json")
      .then((res: any) => setEuro(res.data.eur.rub.toFixed(2)))
      .catch((err: any)=> console.log(err))
  }, []);
  return (
    <ul className="header-items">
      <li>
        <button className="header-btn">
          <svg className="search" width="20" height="20" viewBox="0 0 20 20" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M0.189754 18.9119L5.35343 13.8308C4.00123 12.3616 3.17039 10.4187 3.17039 8.28069C3.17105 3.7071 6.93818 0 11.5855 0C16.2329 0 20 3.7071 20 8.28069C20 12.8543 16.2329 16.5614 11.5855 16.5614C9.57755 16.5614 7.73588 15.8668 6.28927 14.7122L1.10556 19.8134C0.85298 20.0622 0.442921 20.0622 0.190344 19.8134C-0.0628223 19.5646 -0.0628223 19.1607 0.189754 18.9119ZM11.5855 15.2873C15.5177 15.2873 18.7053 12.1504 18.7053 8.28069C18.7053 4.41101 15.5177 1.27403 11.5855 1.27403C7.65333 1.27403 4.46573 4.41101 4.46573 8.28069C4.46573 12.1504 7.65333 15.2873 11.5855 15.2873Z" />
          </svg>
        </button>
      </li>
      <li className="header-burger">
        <button className={`burger${burger ? " active" : ""}`}
          onClick={() => setBurger(oldBurger => !oldBurger)}>
          <span></span>
        </button>
      </li>
      <li className="header-contacts">
        <a href="tel:+79117428710">
          <svg className="phone" width="19" height="19" viewBox="0 0 19 19" fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18 14.8805C18 15.1865 17.9319 15.501 17.7872 15.807C17.6425 16.113 17.4552 16.402 17.2083 16.674C16.7912 17.133 16.3315 17.4645 15.8122 17.677C15.3015 17.8895 14.7481 18 14.1522 18C13.2839 18 12.356 17.796 11.3771 17.3795C10.3981 16.963 9.41913 16.402 8.44867 15.6965C7.45958 14.9741 6.52623 14.1785 5.65648 13.3165C4.79565 12.4512 4.00169 11.5221 3.28142 10.537C2.58337 9.568 2.02153 8.599 1.61292 7.6385C1.20431 6.6695 1 5.743 1 4.859C1 4.281 1.10215 3.7285 1.30646 3.2185C1.51077 2.7 1.83425 2.224 2.28543 1.799C2.83025 1.2635 3.42614 1 4.05608 1C4.29444 1 4.5328 1.051 4.74562 1.153C4.96695 1.255 5.16274 1.408 5.31597 1.629L7.29094 4.4085C7.44417 4.621 7.55483 4.8165 7.63145 5.0035C7.70806 5.182 7.75063 5.3605 7.75063 5.522C7.75063 5.726 7.69104 5.93 7.57186 6.1255C7.46119 6.321 7.29945 6.525 7.09514 6.729L6.44817 7.4005C6.35453 7.494 6.31197 7.6045 6.31197 7.7405C6.31197 7.8085 6.32048 7.868 6.33751 7.936C6.36305 8.004 6.38858 8.055 6.40561 8.106C6.55884 8.3865 6.82273 8.752 7.1973 9.194C7.58037 9.636 7.98898 10.0865 8.43165 10.537C8.89134 10.9875 9.334 11.404 9.78518 11.7865C10.2278 12.1605 10.5939 12.4155 10.8833 12.5685C10.9259 12.5855 10.977 12.611 11.0366 12.6365C11.1047 12.662 11.1728 12.6705 11.2494 12.6705C11.3941 12.6705 11.5048 12.6195 11.5984 12.526L12.2454 11.8885C12.4582 11.676 12.6625 11.5145 12.8583 11.4125C13.0541 11.2935 13.2499 11.234 13.4627 11.234C13.6244 11.234 13.7947 11.268 13.982 11.3445C14.1693 11.421 14.365 11.5315 14.5779 11.676L17.3956 13.6735C17.6169 13.8265 17.7702 14.005 17.8638 14.2175C17.9489 14.43 18 14.6425 18 14.8805Z"
              strokeWidth="1.5" strokeMiterlimit="10" />
          </svg>
          +7 965 001-43-71 </a>
        <a href="mailto:info@vkamne.com">
          <svg className="email" width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.9557 1.89783C17.8809 1.52109 17.7122 1.16502 17.4661 0.86566C17.4161 0.802811 17.3638 0.746898 17.3067 0.69018C16.8688 0.251569 16.2598 -1.90735e-06 15.6356 -1.90735e-06L2.3638 -1.90735e-06C1.73271 -1.90735e-06 1.13917 0.245228 0.69251 0.690426C0.635979 0.746618 0.583561 0.803091 0.53167 0.867937C0.286631 1.16603 0.118689 1.52161 0.0456699 1.89888C0.0151543 2.04704 -0.000244141 2.20055 -0.000244141 2.35585L-0.000244141 11.6444C-0.000244141 11.9675 0.0656387 12.2822 0.196244 12.5813C0.309271 12.847 0.480764 13.0988 0.692369 13.3096C0.745666 13.3624 0.798611 13.4112 0.855143 13.4587C1.27821 13.8079 1.81392 14 2.3638 14H15.6356C16.1891 14 16.7243 13.8071 17.1462 13.4549C17.2026 13.4094 17.2549 13.3617 17.3071 13.3096C17.5117 13.106 17.6742 12.872 17.7911 12.614L17.8065 12.5772C17.9347 12.2838 17.9998 11.9701 17.9998 11.6444V2.35585C17.9998 2.20258 17.9849 2.04805 17.9557 1.89783ZM1.224 1.50347C1.25744 1.4547 1.30012 1.40464 1.35457 1.34995C1.62489 1.08073 1.98338 0.932572 2.36377 0.932572H15.6356C16.0193 0.932572 16.3779 1.08097 16.6453 1.35048C16.6914 1.39697 16.7355 1.44857 16.774 1.50014L16.8755 1.63607L9.79147 7.78839C9.57308 7.97918 9.2919 8.08418 8.99965 8.08418C8.71028 8.08418 8.42935 7.97943 8.20835 7.78864L1.13144 1.63782L1.224 1.50347ZM0.940361 11.7264C0.936529 11.7011 0.93565 11.673 0.93565 11.6444V2.54818L6.43303 7.32705L0.991127 12.0551L0.940361 11.7264ZM16.3439 12.8799C16.1311 13.0022 15.8861 13.0671 15.6356 13.0671H2.3638C2.11324 13.0671 1.86831 13.0022 1.65569 12.8799L1.43322 12.7514L7.05597 7.86855L7.67219 8.40266C8.04182 8.72244 8.51319 8.89873 8.99972 8.89873C9.48804 8.89873 9.96019 8.72244 10.3296 8.40266L10.9455 7.86831L16.5664 12.7517L16.3439 12.8799ZM17.0636 11.6444C17.0636 11.6725 17.0631 11.7003 17.0595 11.7251L17.0108 12.0569L11.5666 7.3296L17.0636 2.55049V11.6444Z" />
          </svg>
          info@vkamne.com</a>
      </li>
      <li className="header-currency">
        <p>
          <svg className="email" width="14" height="23" viewBox="0 0 14 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
              d="M8.16667 1.16667C8.16667 0.52234 7.64435 0 7 0C6.35565 0 5.83333 0.52234 5.83333 1.16667V1.81907C4.46827 1.98266 3.21225 2.4326 2.21216 3.1234C0.928107 4.01036 0 5.35531 0 7C0 8.48632 0.573615 9.86662 1.84077 10.8414C2.84011 11.6101 4.18558 12.0616 5.83333 12.2022V17.9922C4.91878 17.8457 4.13054 17.5325 3.53828 17.1234C2.71108 16.552 2.33333 15.8553 2.33333 15.1667C2.33333 14.5223 1.81099 14 1.16667 14C0.52234 14 0 14.5223 0 15.1667C0 16.8113 0.928107 18.1564 2.21216 19.0433C3.21225 19.734 4.46827 20.184 5.83333 20.3476V21C5.83333 21.6444 6.35565 22.1667 7 22.1667C7.64435 22.1667 8.16667 21.6444 8.16667 21V20.3507C9.52257 20.1951 10.7772 19.7662 11.7803 19.0849C13.0809 18.2015 14 16.8509 14 15.1667C14 13.6423 13.4335 12.2526 12.1518 11.2831C11.1498 10.5252 9.80455 10.0956 8.16667 9.96217V4.17448C9.08122 4.32094 9.86953 4.63416 10.4617 5.04325C11.2889 5.61464 11.6667 6.31135 11.6667 7C11.6667 7.64433 12.189 8.16667 12.8333 8.16667C13.4777 8.16667 14 7.64433 14 7C14 5.35531 13.0719 4.01036 11.7879 3.12341C10.7878 2.43259 9.53178 1.98264 8.16667 1.81907V1.16667ZM5.83333 4.1745C4.91878 4.32095 4.13054 4.63417 3.53828 5.04327C2.71108 5.61464 2.33333 6.31135 2.33333 7C2.33333 7.84701 2.63473 8.50834 3.26342 8.99197C3.78347 9.39202 4.60474 9.72813 5.83333 9.85857V4.1745ZM8.16667 12.3048V17.9973C9.08962 17.8575 9.87957 17.5553 10.4693 17.1547C11.2799 16.6041 11.6667 15.9131 11.6667 15.1667C11.6667 14.2604 11.3582 13.6085 10.7442 13.144C10.2268 12.7527 9.4052 12.4293 8.16667 12.3048Z" />
          </svg>
          {dollar}
        </p>
        <p>
          <svg className="email" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
              d="M12 15.5C9.49 15.5 7.32 14.08 6.24 12H12V10H5.58C5.53 9.67 5.5 9.34 5.5 9C5.5 8.66 5.53 8.33 5.58 8H12V6H6.24C7.32 3.92 9.5 2.5 12 2.5C13.61 2.5 15.09 3.09 16.23 4.07L18 2.3C16.41 0.87 14.3 0 12 0C8.08 0 4.76 2.51 3.52 6H0V8H3.06C3.02 8.33 3 8.66 3 9C3 9.34 3.02 9.67 3.06 10H0V12H3.52C4.76 15.49 8.08 18 12 18C14.31 18 16.41 17.13 18 15.7L16.22 13.93C15.09 14.91 13.62 15.5 12 15.5Z" />
          </svg>
          {euro}
        </p>
      </li>
    </ul>
  );
};

export default HeaderItems;